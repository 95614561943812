import React from "react";
import moment from "moment";

export interface ICSEvent {
  id: string;
  name: string;
  startDatetime: string;
  endDatetime: string;
  description?: string | null;
  joinUrl?: string | null;
  publicUrl?: string | null;
  agenda?: Array<{
    title: string;
  }> | null;
}

function formatAgendaAsHTML(agenda: Array<{ title: string }>): string {
  return agenda.map((item) => `- ${item.title}`).join("\n");
}

/**
 * Generates an ICS file and downloads it.
 * @param events - The list of events to encode.
 * @param programName - The name of the program.
 */
export function generateICSFile(events: ICSEvent[], programName: string) {
  const encodedCalendarData = encodeEventsIntoIcsData(events);
  const dataStr = `data:text/calendar;charset=utf8,${encodedCalendarData}`;

  const downloadLink = document.createElement("a");
  downloadLink.href = dataStr;
  downloadLink.download = `${programName} Live Session${events.length > 1 ? "s" : ""}.ics`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

/**
 * Encodes a list of events into an ICS file.
 * @param events - The list of events to encode.
 * @returns The ICS file data as a string. Parent will handle the download.
 */
export function encodeEventsIntoIcsData(events: ICSEvent[]): string {
  const now = moment.utc().format("YYYYMMDDTHHmmss[Z]");
  const icsContent = [
    "BEGIN:VCALENDAR",
    "VERSION:2.0",
    ...events
      .map((event) => {
        const description = event.description || "";
        const agendaHtml =
          event.agenda && event.agenda.length > 0
            ? `\n\nAgenda:\n${formatAgendaAsHTML(event.agenda)}`
            : "";
        const fullDescription = `${description}${agendaHtml}`;

        // Ensure we're using UTC times directly from the database
        const startTime = moment
          .utc(event.startDatetime)
          .format("YYYYMMDDTHHmmss[Z]");
        const endTime = moment
          .utc(event.endDatetime)
          .format("YYYYMMDDTHHmmss[Z]");

        return [
          "BEGIN:VEVENT",
          `UID:${event.id}`,
          `DTSTAMP:${now}`,
          `DTSTART:${startTime}`,
          `DTEND:${endTime}`,
          `SUMMARY:${event.name}`,
          `DESCRIPTION:${fullDescription
            .replace(/[\,;]/g, "\\$&")
            .replace(/\n/g, "\\n")}`,
          `LOCATION:${event.joinUrl || ""}`,
          `URL:${event.publicUrl || ""}`,
          "END:VEVENT",
        ];
      })
      .flat(),
    "END:VCALENDAR",
  ].join("\r\n");

  return encodeURIComponent(icsContent);
}
