import { Dialog, DialogContent, DialogFooter, Button } from "@mindstonehq/ui";
import calendarDownloadExample from "../../../public/calendar_download_example.png";
import outlookCalendarDemo from "../../../public/outlook_calendar_demo.gif";
import { useIntercom } from "react-use-intercom";
import { ICSEvent } from "~/utils/ics-file-utils";

interface CalendarExportDialogProps {
  isOpen: boolean;
  onClose: () => void;
  events: ICSEvent[];
  programName: string;
}

export function CalendarExportDialog({
  isOpen,
  onClose,
  events,
  programName,
}: CalendarExportDialogProps) {
  const { show: showIntercom } = useIntercom();

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-[800px] overflow-y-auto">
        <div className="space-y-2 mb-4">
          <div className="text-foreground text-lg font-semibold leading-7">
            How to add live sessions to your Calendar
          </div>
          <div className="text-muted-foreground text-sm font-normal leading-tight">
            We'll guide you through this step by step.
          </div>
        </div>
        <div className="space-y-2 mb-4">
          <div className="text-foreground text-base font-medium leading-normal">
            If you are using Google Calendar
          </div>
          <div className="text-muted-foreground text-sm font-normal leading-normal">
            A calendar file has been automatically downloaded. Click on{" "}
            <a
              href="https://support.google.com/calendar/answer/37118"
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              this link
            </a>{" "}
            to view what to do with it.
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 relative">
          <div className="space-y-2">
            <div className="text-foreground text-base font-medium leading-normal">
              If you are using an app on your computer (e.g. Outlook, Apple
              Calendar)
            </div>
            <ol className="list-decimal list-inside space-y-1 text-sm text-muted-foreground">
              <li>
                Double-click the downloaded file to add the event to your
                calendar app.
              </li>
              <li>That's it! You're all set.</li>
            </ol>
            <div className="mt-4 ml-4">
              <img
                src={calendarDownloadExample}
                alt="Calendar download example"
                width="260"
                height="70"
                className="rounded-md"
              />
            </div>
          </div>
          <div className="hidden sm:block absolute left-1/2 top-0 bottom-0 w-px bg-border"></div>
          <div className="space-y-2">
            <div className="text-foreground text-base font-medium leading-normal">
              If you're using Outlook in a web browser
            </div>
            <ol className="list-decimal list-inside space-y-1 text-sm text-muted-foreground">
              <li>Open Outlook in your web browser.</li>
              <li>
                Drag and drop the downloaded <b>.ics</b> file into the browser
                window.
              </li>
              <li>Click "Import" to confirm, and you're done!</li>
            </ol>
            <div className="mt-4 ml-4">
              <img
                src={outlookCalendarDemo}
                alt="Outlook calendar demo"
                width="300"
                height="200"
                className="rounded-md"
              />
            </div>
          </div>
        </div>
        <DialogFooter className="mt-6">
          <Button variant="outline" onClick={showIntercom}>
            Help
          </Button>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
